import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/base/layout"
import Article from "../components/section/article";

export default function Services() {
  const data = useStaticQuery(query);
  return (
    <Layout seo={data.strapiServices.seo} slug={data.strapiServices.slug_de}  lang="de" currentId="2">
      <Article list={data.strapiServices.article} lang="de" />
    </Layout>
  );
}

const query = graphql`
  query {
    strapiServices {
      slug_de
      seo {
        meta_title_de
        meta_description_de
      }
      article {
        type
        subtext_de
        text_de
        button {
          label
          url
        }
      }
    }
  }
`;